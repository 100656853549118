import LazyLoad from "vanilla-lazyload";

const callback_error = function (element) {
    if (element.dataset.placeholder) {
        element.setAttribute("src", element.dataset.placeholder);
        const parent = element.parentNode;

        if (parent.tagName === "PICTURE") {
            let source = null;
            while (source = parent.getElementsByTagName("source")[0]) {
                parent.removeChild(source);
            }
        }
    }
};

export const lazyLoader = new LazyLoad({
    restore_on_error: true, callback_error
});
